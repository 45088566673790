import React, { useContext } from "react";
import * as styles from "./SecondSection.module.css";
import { graphql, useStaticQuery } from "gatsby";
import { languageContext } from "../Header/Header";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const SecondSection = (props) => {
  const llanguage = useContext(languageContext);
  const { language } = llanguage;

  const data = useStaticQuery(graphql`
    query HighlightedContent {
      allStrapiHome {
        edges {
          node {
            HighlightedContent {
              Description
              FirstImage {
                url
              }
              IconImage {
                url
              }
              IconLink {
                href
                label
              }
              SecondImage {
                url
              }
              Link {
                href
                label
              }
            }
            localizations {
              data {
                attributes {
                  HighlightedContent {
                    Description
                    FirstImage {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    IconImage {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                    IconLink {
                      href
                      label
                    }
                    SecondImage {
                      data {
                        attributes {
                          url
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  const localizations = data.allStrapiHome.edges[0]?.node?.localizations || {};
  const {
    FirstImage: localFirstImage,
    IconImage: localIconImage,
    SecondImage: localSecondImage
  } = localizations?.data[0]?.attributes?.HighlightedContent;

  const {
    Description,
    FirstImage,
    //  Link,
    IconImage, IconLink, SecondImage } = language === "it"
      ? localizations?.data[0]?.attributes?.HighlightedContent : data.allStrapiHome.edges[0]?.node?.HighlightedContent || {};


  return (
    <div className={styles.secondSectionWrap}>
      <div className={styles.imageSection}>
        <div className={styles.textSection}>
          <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
            <p className="animate__animated animate__fadeInUp">{Description}</p>
          </AnimationOnScroll>
          <AnimationOnScroll animateIn='animate__animated animate__fadeInUp'>
            <h4 className="animate__animated animate__fadeInUp">
              <a href={IconLink?.href}>{IconLink?.label}</a>
              <span>
                <img src={language === "it"
                  ? localIconImage?.data?.attributes?.url
                  : IconImage?.url} alt="icon" />
              </span>
            </h4>
          </AnimationOnScroll>
        </div>

      </div>
      <div className={styles.bottomImageBox}>
        <div className={styles.bottomImageMain}>
          <img className={styles.secondBannerImg} src={language === "it"
            ? localSecondImage?.data?.attributes?.url
            : SecondImage?.url} alt="icon" />
          <img className={styles.upperImage} src={language === "it"
            ? localFirstImage?.data?.attributes?.url
            : FirstImage?.url} alt="icon" />
        </div>
      </div>
    </div>
  );
};

export default SecondSection;
