import React, { useContext, useEffect } from "react";
import Header from "../Components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import * as styles from "./styles.module.css";
import SectionRoundAnimation from "../Components/SectionRoundAnimation/SectionRoundAnimation";
import SecondSection from "../Components/SecondSection/SecondSection";
import SliderWine from "../Components/SliderWine/SliderWine";
import SeparatorImg from "../assets/images/landingSlider.png";
import "./slickslider.css";
import CtaCard from "../Components/CtaCard/CtaCard";
import Footer from "../Components/Footer/Footer";
import { SEO } from "../Components/Seo/seo";
// import Vini from "./Vini/Vini";
import "./index.css";
import classNames from "classnames";
import SliederImage from "../Components/SliderImage/SliederImage";
import NewsLetter from "../Components/NewsLetter/NewsLetter";
import { graphql } from "gatsby";
import { languageContext } from "../Components/Header/Header";
import CookiesBanner from "../Components/CookiesBanner/CookiesBanner";
import topModalImage from "../assets/images/topModal.png";
import bottomModalImage from "../assets/images/bottomModal.png";
import Modal from "react-modal";

const IndexPage = ({ data }) => {
  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }
  const llanguage = useContext(languageContext);
  const { language } = llanguage;
  const localizations = data.allStrapiHome.edges[0]?.node?.localizations || {};

  const { Seperator: localSeperator } =
    localizations?.data[0]?.attributes || {};
  const { Seperator } =
    language === "it"
      ? localizations?.data[0]?.attributes
      : data?.allStrapiHome?.edges[0]?.node || {};

  const handleUrls = (language) => {
    const urls = [];
    if (language == "it") {
      localSeperator.forEach((item, index) => {
        const url = item?.Image?.data?.attributes?.url;
        urls.push(url);
      });
    } else if (language == "en") {
      Seperator.forEach((item, index) => {
        const url = item?.Image?.url;
        urls.push(url);
      });
    }
    return urls;
  };

  // Call the function with the desired language
  const urlsArray = handleUrls(language);

  const isMobile = typeof window !== "undefined" && window.innerWidth < 767;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      height: isMobile ? "auto" : "460px",
      width: isMobile ? "90%" : "50%",
      transform: isMobile ? "translate(-50%, -45%)" : "translate(-50%, -50%)",
      padding: isMobile ? "48px" : "0 24px",
      maxHeight: "600px",
      maxWidth: isMobile ? "341px" : "700px",
      minWidth: isMobile ? "341px" : "700px",
    },
  };
  useEffect(() => { 
    setTimeout(() => {
      // openModal();
    }, 2000);
  }, []);
  return (
    <main>
      <div className={classNames(styles.mainSection, "")}>
        <Header name="homepage" />
        {/* <CookiesBanner/> */}

        <div className="pageWrapper">
          <SectionRoundAnimation />
        </div>
        <div className="secondSectionWrapper">
          <SecondSection />
        </div>
        <div className="">
          <SliderWine />
        </div>
        <div className="pageWrapper">
          <SliederImage separatorImg={urlsArray} />
        </div>
        <div className="pageWrapper">
          <div className={styles.LeftRightImg}>
            <CtaCard homePage={true} />
          </div>
        </div>
        <div className="newsLetterWrapper">
          {/* <NewsLetter /> */}
        </div>
        {/* <Vini /> */}
        <Modal
          isOpen={modalIsOpen}
          className={styles.modalContainer}
          onRequestClose={closeModal}
          style={customStyles}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            class="w-6 h-6"
            className={styles.closeIcon}
            onClick={closeModal}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18 18 6M6 6l12 12"
            />
          </svg>

          <img className={styles.topModalImage} src={topModalImage} alt="" />
          <img
            className={styles.bottomModalImage}
            src={bottomModalImage}
            alt=""
          />
          <div className={styles.modalContents}>
            <div className={styles.modalContent}>
              <p className={styles.dateText}>February 12-14</p>
              <div className={styles.innerText}>
                <h2>WINE PARIS & VINEXPO PARIS</h2>
              </div>
              <p className={styles.hallText}>HALL 2.2 D134</p>
            </div>
            <div className={styles.modalContent}>
              <p className={styles.dateText}>March 10-12</p>
              <div className={styles.innerText}>
                <h2 className={styles.singleText}>PROWEIN</h2>
                <h6>DÜSSELDORF</h6>
              </div>
              <p className={styles.hallText}>HALL 15 – STAND G81</p>
            </div>
            <div className={styles.modalContent}>
              <p className={styles.dateText}>April 14-17</p>
              <div className={styles.innerText}>
                <h2 className={styles.singleText}>VINITALY</h2>
                <h6>INTERNATIONAL WINE AND SPIRITS EXHIBITION</h6>
              </div> 
              <p className={styles.hallText}>HALL 6 - STAND E9</p>
            </div>
          
          </div>
        </Modal>
      </div>
      <Footer />
    </main>
  );
};

export default IndexPage;

export const Head = () => <SEO />;

export const query = graphql`
  query MyQuery {
    allStrapiHome {
      edges {
        node {
          Seperator {
            Image {
              url
            }
          }
          localizations {
            data {
              attributes {
                Seperator {
                  Image {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
