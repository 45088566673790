import React from "react";
import * as styles from "./WineCard.module.css";
import IconArrow from "../IconArrow/IconArrow";


const WineCard = (props) => {
  const { wineHeading, wineImage, hoverImage } = props;
  return (
    <div
      className={styles.wineCard}>
      <div className={styles.wineHeading}>
        <span>{wineHeading}</span> <IconArrow />
      </div>
      <img src={wineImage} alt="hoverimage"
        style={{ cursor: `url(${hoverImage}) 64 64,  auto` }}
      />
    </div>
  );
};

export default WineCard;
