// extracted by mini-css-extract-plugin
export var afterSlide = "SliderWine-module--afterSlide--d441e";
export var bottomImageBox = "SliderWine-module--bottomImageBox--3e1cf";
export var brandImage = "SliderWine-module--brandImage--13187";
export var cardLink = "SliderWine-module--cardLink--906ae";
export var content = "SliderWine-module--content--daf00";
export var nextButton = "SliderWine-module--nextButton--71459";
export var prevButton = "SliderWine-module--prevButton--66c04";
export var slickArrow = "SliderWine-module--slick-arrow--e477f";
export var sliderBoxs = "SliderWine-module--sliderBoxs--89b41";
export var sliderItem = "SliderWine-module--sliderItem--948e5";
export var sliderSection = "SliderWine-module--sliderSection--3cbe8";
export var sliderWrapper = "SliderWine-module--sliderWrapper--551c7";
export var swiper = "SliderWine-module--swiper--5c063";
export var swiperButtonPrev = "SliderWine-module--swiper-button-prev--656b9";
export var swiperSlide = "SliderWine-module--swiper-slide--aa6f2";
export var swiperSlideActive = "SliderWine-module--swiper-slide-active--53bcf";
export var swiperSlideDuplicateActive = "SliderWine-module--swiper-slide-duplicate-active--64c33";
export var swiperWrapper = "SliderWine-module--swiper-wrapper--57053";
export var wineCardSlider = "SliderWine-module--wineCardSlider--b405d";