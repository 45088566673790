import React from "react";
import * as styles from "./SectionRoundAnimation.module.css";
// import roundImage from "../../assets/images/roundanimation.png";
import { graphql, useStaticQuery } from "gatsby";

const SectionRoundAnimation = () => {
  // window.onscroll = function () {
  //     var theta = document.documentElement.scrollTop / 50 % Math.PI;

  //     document.getElementById('js-logo').style.transform = 'rotate(' + theta + 'rad)';
  // }

  const data = useStaticQuery(graphql`
    query MySecondRoundAnimation {
      allStrapiComponentSlicesHero {
        nodes {
          HeroImage {
            url
          }
        }
      }
    }
  `);
  const { url } = data.allStrapiComponentSlicesHero.nodes[0].HeroImage[0];

  return (
    <div className={styles.scrollBox}>
      <div className={styles.roundBrandBox}>
        <img src={url} alt="brand" />
      </div>
    </div>
  );
};

export default SectionRoundAnimation;
