// extracted by mini-css-extract-plugin
export var LeftRightImg = "styles-module--LeftRightImg--04b2e";
export var bottomModalImage = "styles-module--bottomModalImage--f650a";
export var closeIcon = "styles-module--closeIcon--7e8bb";
export var dateText = "styles-module--dateText--7293e";
export var hallText = "styles-module--hallText--8b74d";
export var innerText = "styles-module--innerText--af6de";
export var mainSection = "styles-module--mainSection--be6e1";
export var modalContent = "styles-module--modalContent--b7f66";
export var modalContents = "styles-module--modalContents--b51b9";
export var singleText = "styles-module--singleText--7367c";
export var topModalImage = "styles-module--topModalImage--f0c53";